import Vue from 'vue';
import VueGtag from "vue-gtag";
import "@/multilanguage/main";
import App from './App.vue'
import { router } from '@/router/index';
import LoadScript from 'vue-plugin-load-script';
import VueMeta from 'vue-meta';
import vSelect from 'vue-select';

Vue.use(LoadScript);
Vue.use(VueMeta);

Vue.use(VueGtag, {
  config: { id: "G-KE5WFTYSKW" }
});

Vue.component('v-select', vSelect);

// Vue.loadScript("./scripts/bitrix-chat.js")

Vue.config.productionTip = true;

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
