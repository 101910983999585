export default {
    capabilities: "Можливості",
    how_it_works: "Як це працює",
    tariffs: "Тарифи",
    login_uppercase: "УВІЙТИ",
    first_section_title: "Ефективна аналітика земельних ділянок",
    first_section_description: "Зручне отримання відомостей із державних реєстрів та порталів відкритих даних",
    try_for_free: "спробувати",
    what_can_the_cadastre_ua_system: "Що може система Кадастр UA",
    it_is_convenient_to_search_for_information: "Зручно шукати інформацію",
    receive_store_and_analyze_data: "Отримувати, зберігати та аналізувати дані",

    how_it_works_1_description: "Зареєструйтесь та отримайте можливість керувати даними у власному кабінеті.",
    how_it_works_2_description: "Створіть список із кадастрових номерів, за якими Вам потрібна інформація.",
    how_it_works_3_description: "Сервіс Кадастр UA знайде всю наявну інформацію в Державних реєстрах та серед відкритих даних.",
    how_it_works_4_description: "Скористайтесь аналітикою, яку надасть Вам сервіс, або вивантажіть отримані дані в зручному форматі.",
    how_it_works_5_description: "Візьміть інформацію із собою завдяки єдиному з мобільним додатком акаунту.",

    information_from_registers_and_open_data: "інформація з реєстрів та порталів відкритих даних",
    number_of_land_plots_in_one_request: "Кількість земельних ділянок в одному запиті",
    up_to_1000_pcs: "до 1000 шт",
    up_to_10000_pcs: "до 10 000 шт",
    more_than_10000_pcs: "більше 10 000 шт",
    more_than_10000_pcs_2: "10 000 + шт",
    price_tag: "{0} грн/шт",
    DZK: "ДЗК",
    DRRP: "ДРРП",
    NGO: "НГО",
    plot_vector: "Вектор ділянки",
    complete_analytics: "Повна аналітика",
    to_start_work_you_need_a_deposit_of_2000_uah: "Для початку роботи необхідний депозит у розмірі 2000 грн",

    what_system_can_do__it_is_convenient_to_search_for_information__information_1: "Безпосередньо на мапі: працювати як з окремою ділянкою, так і з групою",
    what_system_can_do__it_is_convenient_to_search_for_information__information_2: "Завантажувати дані з файлу",
    what_system_can_do__it_is_convenient_to_search_for_information__information_3: "Використовувати рядок пошуку за різними параметрами: кадастровий номер, код КОАТУУ, географічні координати, географічні назви",

    what_system_can_do__receive_store_and_analyze_data__information_1: "Отримання витягів із реєстрів. Наразі доступні дані з таких сервісів: ПККУ, НГО, ДРРП, ДЗК",
    what_system_can_do__receive_store_and_analyze_data__information_2: "Вивантаження отриманих даних у різних форматах: таблиці (xlsx), координати (kml, geojson), документи (pdf)",
    what_system_can_do__receive_store_and_analyze_data__information_3: "Збереження історії та результатів пошуку",
    what_system_can_do__receive_store_and_analyze_data__information_4: "Зручне фільтрування данних з відображенням результатів на мапі",
    what_system_can_do__receive_store_and_analyze_data__information_5: "Можливість проводити аналіз отриманих даних у різних площинах",

    information_is_always_there_even_in_the_absence_of_the_internet: "Інформація завжди поруч, навіть за відсутністю інтернету",
    footer_copyright: "© 2023 Кадастр UA. Всі права захищені.",
    footer_public_offer: "Публічна оферта.",
    information_from_registers_and_open_data_capitalize: "Iнформація з реєстрів та відкритих даних",
    price: "Ціна",
    DEMO: "ДЕМО",
    signIn: "Увiйти",
    cadastr: "Кадастр UA",
    contact_us: "Зв’яжіться з нами",
}
