import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import ua from "@/multilanguage/ua";
import en from "@/multilanguage/en";
Vue.use(MLInstaller);

export default new MLCreate({
    initial: 'ukraine',
    save: process.env.NODE_ENV === 'production',
    languages: [
        new MLanguage('ukraine').create(ua),
        new MLanguage('english').create(en),
    ]
})
