<template>
    <div data-component="Header">
        <div class="wrapper">
            <div class="header">
                <div class="header__logo">
                  <router-link
                    to="/"
                  >
                    <img src="@/assets/icons/logo.svg" alt="Logo">

                    {{ $ml.get("cadastr") }}
                  </router-link>
                </div>

                <ul class="header__menu">
                    <li class="header__menu__item">
                      <router-link
                        to="/#WhatSystemCanDo"
                      >
                        {{ $ml.get("capabilities") }}
                      </router-link>
                    </li>

                    <li class="header__menu__item">
                      <router-link
                        to="/#HowItWorks"
                      >
                        {{ $ml.get("how_it_works") }}
                      </router-link>
                    </li>

                    <li class="header__menu__item">


                        <v-select
                                v-model="lang"
                                class="style-chooser"
                                :options="$ml.list"
                                :clearable="false"
                                :filterable="false"
                                :searchable="false"
                                @input="$ml.change(lang)"
                        >
                            <template #selected-option="{ label }">
                                <img
                                    :src="require(`@/assets/images/${label}.png`)"
                                    :alt="label"
                                    class="mr-1">
                            </template>
                            <template #option="{ label }">
                                <img :src="require(`@/assets/images/${label}.png`)"  :alt="label"> {{ label }}
                            </template>
                        </v-select>

                    </li>

                    <li class="header__menu__item sign-in__outer">
                        <a
                          class="sign-in"
                          href="https://map.kadastr-ua.com/?auth"
                          target="_blank"
                        >
                          {{ $ml.get("signIn") }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data(){
      return {
        lang: {
          label: 'ukraine',
        }
      }
    }

}
</script>

<style scoped>

</style>
