<template>
    <div class="layout" data-component="layout">
        <header-menu />
      <transition
        name="slide-right"
        mode="out-in"
        >
        <router-view />
      </transition>


        <Footer />

        <scroll-up />
    </div>
</template>

<script>
import HeaderMenu from "@/layouts/HeaderMenu";
import Footer from "@/layouts/Footer";

import ScrollUp from "@/layouts/ScrollUp";

export default {
    name: "layout",
    components: {
        HeaderMenu,
        Footer,
        ScrollUp,
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/_layout.scss';
</style>
