<template>
    <div class="footer">
        <div class="wrapper">
            <div class="footer__inner">
                <div class="footer__inner__some-information">
                    <div class="logo">
                        <img src="@/assets/icons/logo.svg" alt="Logo">
                        {{ $ml.get("cadastr") }}
                    </div>

                    <div class="copyright">
                        {{ $ml.get("footer_copyright") }}
                    </div>
                </div>

                <div class="footer__inner__contact">
                  <div class="footer__inner__mail">
                        <span class="title">{{ $ml.get("contact_us") }}</span>

                        <img src="@/assets/icons/mail.svg"> <a href="mailto:info@kadastr-ua.com">info@kadastr-ua.com</a>

                        <a
                            v-for="network in socialNetworks"
                            :key="network.link"
                            :href="network.link"
                            class="social-link"
                            target="_blank"
                        >
                          <img :src="network.img" :alt="network.name">
                        </a>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FacebookIcon from "@/assets/icons/facebook.svg";
// import InstagramIcon from "@/assets/icons/instagram.svg";
// import LinkedInIcon from "@/assets/icons/linkedin.svg";

export default {
    name: "Footer",
    data() {
        return {
            socialNetworks: [
                {
                    img: FacebookIcon,
                    name: "Ми у Facebook",
                    link: "https://www.facebook.com/CadastrUA/",
                },
            ]
        };
    },
}
</script>
