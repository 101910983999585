<template>
    <div data-component="ScrollUp" class="scroll-up" v-show="isMoreThanOneScreen">
        <button @click="scrollUp">
            <img src="@/assets/icons/scroll-up.svg" alt="Scroll up">
        </button>
    </div>
</template>

<script>
export default {
    name: "ScrollUp",
    data() {
        return {
            windowHeight: window.innerHeight,
            pageYOffset: window.pageYOffset,
        };
    },
    mounted() {
        window.addEventListener('resize', this.setWindowHeight);
        window.addEventListener('scroll', this.setPageYOffset);
    },
    methods: {
        scrollUp() {
            window.scrollTo(0, 0);
        },
        setWindowHeight() {
            this.windowHeight = window.innerHeight;
        },
        setPageYOffset() {
            this.pageYOffset = window.pageYOffset;
        },
    },
    computed: {
        isMoreThanOneScreen() {
            return (this.windowHeight < (this.pageYOffset + this.windowHeight) - 100);
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setWindowHeight);
        window.removeEventListener('scroll', this.setPageYOffset);
    },
}
</script>
