<template>
    <div id="app">
        <layout></layout>
    </div>
</template>

<script>
import layout from "@/layouts/layout";
import ogImage from '@/assets/icons/logo.svg';

export default {
    name: 'App',
    metaInfo: {
        title: 'Кадастр UA - Ефективна аналітика земельних ділянок',
        meta: [
            {
                'name': 'description',
                'content': 'Зручне отримання відомостей із державних реєстрів та порталів відкритих даних',
            },
            {
                'property': 'og:url',
                'content': window.location.origin,
            },
            {
                'property': 'og:type',
                'content': 'website',
            },
            {
                'property': 'og:title',
                'content': 'Кадастр UA - Ефективна аналітика земельних ділянок',
            },
            {
                'property': 'og:description',
                'content': 'Зручне отримання відомостей із державних реєстрів та порталів відкритих даних',
            },
            {
                'property': 'og:image',
                'content': window.location.origin + '/' + ogImage,
            }
        ]
    },
    components: {
        layout,
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
