import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// pages
import Index from '@/components/Pages/Index';

const routes = [
    {
        path: '/',
        name:'',
        component: Index,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }

        window.scrollTo(0,0);
    }
});

export { router };
