<template>
    <div class="Index" data-component="Index">
        <functionality-are-temporarily-limited/>
        <first-page/>
        <what-system-can-do />
        <about-applications />
        <how-it-works />
        <rates v-if="false" />
    </div>
</template>

<script>
const FunctionalityAreTemporarilyLimited = () => import('@/components/FunctionalityAreTemporarilyLimited')
const FirstPage = () => import('@/components/FirstPage')
const WhatSystemCanDo = () => import('@/components/WhatSystemCanDo')
const AboutApplications = () => import('@/components/AboutApplications')
const HowItWorks = () => import('@/components/HowItWorks')
const Rates = () => import('@/components/Rates')

export default {
    name: "Index",
    components: {
        FunctionalityAreTemporarilyLimited,
        FirstPage,
        WhatSystemCanDo,
        AboutApplications,
        HowItWorks,
        Rates,
    }
}
</script>

<style scoped>

</style>
